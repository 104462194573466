<template>
  <div class="w-full lg:w-7/10">
    <h6>Refer a friend</h6>
    <div class="mb-3 mt-5" v-if="showGenerateCode">
      <div
        class="w-48px h-48px rounded-full flex justify-center items-center bg-blue-500 bg-opacity-85"
      >
        <ion-icon
          name="share-social-outline"
          class="text-xl text-gray-700"
        ></ion-icon>
      </div>
      <div class="pt-6 pb-6 opacity-50">
        <p class="text-12 text-credpal-700">
          You currently have no referral link or code.<br />
          Please click the link below to generate.
        </p>
      </div>
      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-blue btn-md text-center"
          :disabled="loading"
          @click="generateLink"
        >
          <span v-if="loading">loading...</span>
          <span v-else>Generate Link</span>
        </button>
      </div>
    </div>
    <div v-else>
      <section>
        <div class="flex justify-between mt-10">
          <div class="flex flex-row">
            <div
              class="w-48px h-48px rounded-full flex justify-center items-center bg-blue-500 bg-opacity-85"
            >
              <ion-icon
                name="cash-outline"
                class="text-2xl text-blue-500 z-50"
              />
            </div>
            <div class="flex flex-col ml-4 mt-1">
              <span class="text-12 font-normal">Wallet Balance</span>
              <sm-loader v-if="loading" />
              <div class="flex" v-else>
                <span class="text-18 font-semibold mr-2">{{
                  totalEarned | formatAmount
                }}</span>
                <!-- <div class="badge badge-green-soft-outline text-xs py-0 px-1" v-if="walletStatus == 'active'">
                Active
              </div>
              <div class="badge badge-red-soft-outline" v-else-if="walletStatus == 'inactive'">
                Inactive
              </div>
              <div class="badge badge-orange-soft-outline" v-else>
                No wallet
              </div> -->
              </div>
              <button
                class="px-4 py-2 bg-blue-500 text-center text-xs text-white rounded-sm mt-2"
                :disabled="disableWallet"
                @click="openConfirmModal"
                v-if="!disableWallet"
              >
                Transfer to Reward
              </button>
            </div>
          </div>
        </div>
        <div class="grid mt-8 grid-cols-1 md:grid-cols-4">
          <div class="col-span-1 flex md:mt-4">
            <div
              class="rounded-full w-12 h-12 md:h-16 md:w-16 flex mr-4 items-center justify-center"
              style="background: rgb(210 198 235);"
            >
              <img :src="icon.referral" />
            </div>
            <div class="flex justify-center flex-col">
              <p class="text-xs font-light">Referral Code</p>
              <p class="text-base font-bold">{{ referralCode }}</p>
            </div>
          </div>
          <div class="col-span-1 flex mt-4">
            <div
              class="rounded-full w-12 h-12 md:h-16 md:w-16 bg-blue-200 flex mr-4 items-center justify-center"
            >
              <img :src="icon.people" />
            </div>
            <div class="flex justify-center flex-col">
              <p class="text-xs font-light">Total Referred</p>
              <p class="text-base font-bold">
                {{ totalReferred }}
              </p>
            </div>
          </div>
          <div class="col-span-1 flex mt-4">
            <div
              class="rounded-full w-12 h-12 md:h-16 md:w-16 bg-green-200 flex mr-4 items-center justify-center"
            >
              <vector :src="icon.cash" alt="icon" />
            </div>
            <div class="flex justify-center flex-col">
              <p class="text-xs font-light">Total Earned</p>
              <p class="text-base font-bold">₦{{ totalEarned | currency }}</p>
            </div>
          </div>
          <div class="col-span-1 flex mt-4">
            <div
              class="rounded-full w-12 h-12 md:h-16 md:w-16 bg-gray-200 flex mr-4 items-center justify-center"
            >
              <vector :src="icon.direction" alt="icon" />
            </div>
            <div class="flex justify-center flex-col">
              <p class="text-xs font-light">Total Transfered</p>
              <p class="text-base font-bold">
                ₦{{ totalTransferred | currency }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-10">
        <h6>Referral Link</h6>
        <div class="flex mt-8 relative w-full md:w-7/10">
          <div
            class="flex-grow-2 border border-gray-300 py-2 px-4 truncate mr-2 text-10 md:text-base rounded-sm"
          >
            {{ referralLink }}
          </div>
          <div class="mr-2">
            <button
              class="btn btn-blue btn-sm whitespace-no-wrap relative md:text-base"
              @click.prevent="copyCode('adUrl')"
            >
              <ion-icon name="copy-outline" class="mr-2"></ion-icon>
              <span>copy</span>
              <div
                v-if="copied"
                class="text-xs bg-white text-center rounded absolute copy-clipboard capitalize"
              >
                Link copied
              </div>
            </button>
            <input
              type="hidden"
              id="adUrl"
              ref="shareUrl"
              :value="referralLink"
            />
          </div>
          <div>
            <button
              class="btn bg-white btn-sm  border border-blue-500 md:text-base"
              @click.prevent="displaySocialShare"
            >
              <ion-icon name="share-outline" class="mr-2"></ion-icon>
              <span>share</span>
            </button>
          </div>
          <div
            class="absolute social-logo-container w-48 py-2 px-4 border border-ash-950 justify-between rounded"
            v-if="showSocialShare"
          >
            <a :href="facebookShare" target="_blank">
              <div class="flex social-item cursor-pointer">
                <div
                  class="w-8 h-8 flex items-center justify-center rounded-full bg-ash-950 text-sm md:mr-3"
                >
                  <ion-icon name="logo-facebook" class="text-white"></ion-icon>
                </div>
                <span
                  class="hidden md:flex text-ash-950 flex justify-center items-center"
                  >Facebook</span
                >
              </div>
            </a>
            <a :href="linkedinShare" target="_blank">
              <div class="flex social-item cursor-pointer">
                <div
                  class="w-8 h-8 flex items-center justify-center rounded-full bg-ash-950 text-white text-sm md:mr-3"
                >
                  <ion-icon name="logo-linkedin"></ion-icon>
                </div>
                <span
                  class="hidden md:flex text-ash-950 flex justify-center items-center"
                  >Linkedin</span
                >
              </div>
            </a>
            <a :href="twitterShare" target="_blank">
              <div class="flex social-item cursor-pointer">
                <div
                  class="w-8 h-8 flex items-center justify-center rounded-full bg-ash-950 text-white text-sm md:mr-3"
                >
                  <ion-icon name="logo-twitter"></ion-icon>
                </div>
                <span
                  class="hidden md:flex text-ash-950 flex justify-center items-center"
                  >Twitter</span
                >
              </div>
            </a>
            <a :href="whatsappShare" target="_blank">
              <div class="flex social-item cursor-pointer">
                <div
                  class="w-8 h-8 flex items-center justify-center rounded-full bg-ash-950 text-white text-sm md:mr-3"
                >
                  <ion-icon name="logo-whatsapp"></ion-icon>
                </div>
                <span
                  class="hidden md:flex text-ash-950 flex justify-center items-center"
                  >Whatsapp</span
                >
              </div>
            </a>
          </div>
        </div>
      </section>
      <section class="mt-10">
        <h6>Referral History</h6>
        <div class="mt-8 w-full md:w-7/10">
          <div class="lg:w-10/12">
            <datatable
              :url="`${this.$baseurl}/personal/users/referral`"
              :ajax="true"
              :ajaxPagination="true"
              :data="referrals.data"
              :columns="referrals.columns"
              :limit="5"
              :loading="referrals.loading"
              :showHeadings="false"
              :fillable="false"
              table-style="flush"
              ref="table"
            />
          </div>
        </div>
      </section>
    </div>

    <modal
      className="w-11/12 sm:w-5/12 xl:w-4/12 flex flex-col"
      ref="confirmTransferReward"
    >
      <template v-if="getFormError(walletResource)">
        <div class="alert alert-red-soft mb-2">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(walletResource) }}</span>
        </div>
      </template>
      <h4 class="text-center text-18 leading-relaxed">
        You are about to transfer the reward earned
      </h4>
      <p class="text-center text-14 mt-2">
        Are you sure you want to proceed?
      </p>
      <div class="mt-5 flex justify-center items-center">
        <button
          type="button"
          class="button bg-blue-500 hover:bg-blue-600 text-white mb-3 px-14"
          :disabled="disableWallet || loading"
          @click.prevent="transferReward"
        >
          Yes, Transfer
        </button>
      </div>
    </modal>

    <modal
      className="w-11/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24"
      ref="redeemSuccessModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-lg font-bold mb-4">
        "Success!"
      </div>
      <div class="text-16 mb-10">Transfer Successful</div>
      <div class="flex justify-center">
        <button
          type="button"
          class="button bg-blue-500 hover:bg-blue-600 px-12 text-white mt-2"
          @click.prevent="closeRedeemSuccessModal"
        >
          Close
        </button>
      </div>
    </modal>

    <modal
      className="w-11/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-lg font-bold mb-4">
        "Success!"
      </div>
      <div class="text-16 mb-10">{{ successMessage }}</div>
      <div class="flex justify-center">
        <button
          type="button"
          class="button bg-blue-500 hover:bg-blue-600 px-12 text-white mt-2"
          @click.prevent="closeSuccessModal"
        >
          Close
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: {
        referral: require("@/assets/icons/sm/share-icon.svg"),
        people: require("@/assets/icons/sm/people.svg"),
        cash: require("@/assets/icons/sm/cash-green.svg"),
        direction: require("@/assets/icons/sm/direction.svg"),
      },
      successMessage: "",
      errorMessage: "",
      referrals: this.$options.resource([], {
        loading: false,
        columns: [
          {
            name: "full_name",
            th: "Full Name",
            className: "w-full whitespace-normal",
            render: (referrals) => {
              const { created_date, id } = referrals;
              return `
               <div class="text-gray-700 font-medium text-sm">${id.substring(
                 0,
                 6
               )}</div>
                <div class="text-gray-500 text-xs">${this.$options.filters.dateFormat(
                  created_date,
                  // "M dd Y"
                  "D, dd M Y"
                )}</div>
              `;
            },
          },
          {
            align: "right",
            name: "status",
            th: "Status",
            render: (referrals) => {
              var amount = this.$options.filters.currency(
                referrals.referred_commission
              );
              let statusColor = "";

              switch (referrals.status) {
                case "processing":
                case "incomplete-signup":
                  statusColor = "bg-yellow-500";
                  break;
                case "declined":
                  statusColor = "bg-red-500";
                  break;

                case "approved":
                default:
                  statusColor = "bg-green-500";
                  break;
              }

              return `
                  <div class="text-gray-700 font-medium text-sm">₦${amount}</div>
                  <div class="text-gray-500 text-xs flex items-center justify-end">
                    <span class="w-5px h-5px rounded-50 ${statusColor} inline-block mr-2"></span>
                    <span>${referrals.status}</span>
                  </div>
                `;
            },
          },
        ],
      }),
      showSocialShare: false,
      shareUrl: "",
      copied: false,
      loading: false,
      referralSummary: {},
      shareDescription:
        "Never miss an opportunity with a CredPal Credit Card. Sign up today!",
      walletResource: this.$options.resource([]),
    };
  },
  computed: {
    twitterShare() {
      return `https://twitter.com/intent/tweet?text=Never miss an opportunity with a CredPal Credit Card. Sign up today!.${this.referralLink}&hashtags=loans,creditCard,money,cash`;
    },
    facebookShare() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.referralLink}&description=Never miss an opportunity with a CredPal Credit Card. Sign up today!&quote=Never miss an opportunity with a CredPal Credit Card. Sign up today!. ${this.referralLink}&quote=${this.referralLink}`;
    },
    linkedinShare() {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.referralLink}`;
    },
    whatsappShare() {
      return `https://wa.me/?text=${this.shareDescription}%0D%0A${this.referralLink}`;
    },
    referralCode() {
      return this.user?.profile?.referral_code || "N/A";
    },
    referralLink() {
      let link;
      switch (process.env.VUE_APP_ENV) {
        case "production":
          link = "https://www.app.credpal.com/register";
          break;
        case "uat":
          link = "https://uat.corporate.credpal.com/register";
          break;
        case "test":
          link = "https://test.corporate.credpal.com/register";
          break;
        case "dev":
          link = "http://dev.corporate.credpal.com/register";
          break;

        default:
          link = "N/A";
          break;
      }
      return this.user?.profile?.referral_link || link;
    },
    totalReferred() {
      return this.referralSummary?.total_referred || 0;
    },
    totalEarned() {
      return this.referralSummary?.total_earned || 0;
    },
    totalTransferred() {
      return this.referralSummary?.total_transferred || 0;
    },
    walletBalance() {
      return this.walletResource?.data?.balance || 0;
    },
    walletStatus() {
      return this.walletResource?.data?.status;
    },
    disableWallet() {
      return this.totalEarned <= 0;
    },
    showGenerateCode() {
      const referralLink = this.user?.profile?.referral_link;
      const referralCode = this.user?.profile?.referral_code;
      if (referralLink && referralCode) {
        return false;
      }
      return true;
    },
  },
  beforeMount() {
    this.getSummary();
    this.getWalletBalance();
  },
  methods: {
    openConfirmModal() {
      this.walletResource.error = "";
      this.$refs.confirmTransferReward.open();
    },
    closeRedeemSuccessModal() {
      this.walletResource.error = "";
      this.$refs.redeemSuccessModal.close();
    },
    displaySocialShare() {
      this.showSocialShare = !this.showSocialShare;
    },
    closeSuccessModal() {
      window.location.reload();
      this.$refs.successModal.close();
    },
    copyCode() {
      this.copied = true;
      const url = this.$refs.shareUrl;

      url.setAttribute("type", "text");
      url.select();
      url.setSelectionRange(0, 99999);
      document.execCommand("copy");
      url.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();

      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    async getSummary() {
      this.loading = true;

      await this.sendRequest("personal.shareAndEarn.summary", {
        success: (response) => {
          this.referralSummary = response.data.data;
        },
        error: (error) => console.log(error),
      });

      this.loading = false;
    },
    async getWalletBalance() {
      this.walletResource.loading = true;
      await this.sendRequest("personal.shareAndEarn.walletBalance", {
        success: (response) => (this.walletResource.data = response.data.data),
        error: (error) => console.log(error),
      });
      this.walletResource.loading = false;
    },
    async transferReward() {
      this.loading = true;
      await this.sendRequest("personal.shareAndEarn.redeem", {
        success: async () => {
          await this.getSummary();
          this.$refs.confirmTransferReward.close();
          this.$refs.redeemSuccessModal.open();
        },
        error: (error) => (this.walletResource.error = error),
      });
      this.loading = false;
    },
    async generateLink() {
      this.loading = true;
      await this.sendRequest("personal.shareAndEarn.generateLink", {
        success: () => {
          this.successMessage = "Link generated successfuly.";
          this.$refs.successModal.open();
        },
        error: () => (this.errorMessage = "Error generating link"),
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.social-logo-container {
  display: flex;
  right: 0%;
  /* top: -100%; */
  bottom: 110%;
}
.social-item:hover span {
  color: #222222;
  font-weight: 700;
}

.social-item:hover div {
  background-color: #171717;
}

.copy-clipboard {
  padding: 0.5rem;
  bottom: 119%;
  width: 80px;
  color: black;
  background-color: rgb(212 208 208 / 60%);
}
.copy-clipboard::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(212 208 208 / 60%) transparent transparent transparent;
  --bg-opacity: 1;
}

@media only screen and (min-width: 768px) {
  .social-logo-container {
    display: grid;
    bottom: 0%;
    left: 102%;
    grid-gap: 0.5rem;
    top: 0%;
    height: fit-content;
  }
}
</style>
